<template>
    <div>            
            <section class="hero" v-if="job.showTruckJobsList">
                <div class="hero-body p-3 has-background-white">
                    <div class=" kahban-wall">
                        <!--################################          PLANNING     ############################-->
                        <div class=" p-1 is-4 bordered kboard planning-board">
                            <b class="pl-1 columns m-0">
                                <span style="" class="column p-0 is-6">PLANNING</span>
                                <div class="column p-0">
                                    <div title="Material's type" style="" class="sm-font mt-1">MATERIALS  </div>
                                </div>
                                <div class="column p-0">
                                    <div title="Stops"  class="mt-1 sm-font">STOPS  </div>
                                </div>
                                <div class="column p-0">
                                    <div class="has-text-centered sm-font mt-1">CREATED BY  </div>
                                </div>
                            </b>
                            <transition-group name="list" tag="ul" class="mt-1 truck-list-items custom_scrollbar-1">
                                <li v-for="(truck_job, index) in $parent.jobs(job).filter(e => e.status_id == 0)" 
                                    :key="'trap' + index" class="is-clickable truck-kanboard truck-item truck-list-item columns "
                                    :class="{'has-background-not-opaque': truck_job.collapsed}" @click.prevent="$parent.$parent.collapse_truck_job(truck_job.guid)">
                                    <i class="fas fa-thumbtack fa-xs is-pulled-left column is-1 p-0 is-align-self-center mr-3 ml-1" 
                                            :class="{'has-text-success-dark': truck_job.collapsed, 'has-text-grey-light': !truck_job.collapsed}"></i>
                                    <div class="column test is-1 mr-1 p-0">
                                    	<vue-ellipse-progress                   
                                            :progress="truck_job_percantage(truck_job)"
                                            :size="42"
                                            :thickness="3"
                                            emptyThickness="3%"
                                            lineMode="in-over"
                                            :legend="true"
                                            :legendValue="truck_job_percantage(truck_job)"
                                            :legendFormatter="({currentValue}) => new Intl.NumberFormat('de-DE').format(currentValue)"
                                            legendClass="legend-custom-style"
                                            dash="90 0.9"
                                            animation="reverse 700 400">
																			</vue-ellipse-progress>
                                    </div>
                                <!-- <transition name="slide-fade" mode="out-in" :duration="10000" appear :key="'trans' + index"> -->
                                    <truck v-if="truck_job.status_id == 0" class="column is-4 p-0 mx-2 truck-elemets is-align-self-center"
                                        :weights="$parent.truckJobWeightVolume(truck_job)"
                                        :show="false"
                                        :job="truck_job"
                                        :data="$parent.getTruckData(truck_job.details.truck_id, $parent.$parent.warehouses_parameters)"></truck>
                                <!-- </Materials>  -->
                                    <div class="column p-0">
                                        <div class="has-text-centered"><b>{{truck_job.Qty.length}}</b></div>
                                    </div>
                                    <!--stop's amount-->
                                    <div class="column p-0">
                                        <div style="margin-left:-15px;" v-if="truck_job.stops.length > 0" class="has-text-centered"><b>{{truck_job.stops.length}}</b></div>
                                    </div>
                                    <div class="column p-0 m-0 has-text-grey-light pr-2 has-text-right">
                                        <div title="Created by" class="sm-font">{{truck_job.creator}}</div>
                                        <div class="sm-font">{{truck_job.updated_at ? truck_job.updated_at : truck_job.created_at | local-datetime}}</div>
                                    </div>
                                </li>
                            </transition-group>
                        </div>
                        <!--################################          LOADING       ############################-->
                        <div class=" p-1 is-4  bordered kboard loading-board">
                            <b class="pl-1 columns m-0">
                                <span class="column p-0 is-6">LOADING</span>
                                <div class="column p-0">
                                    <div title="Loaded amount" style="margin-left:-15px;" class="sm-font mt-1">LOADED  </div>
                                </div>
                                <div class="column p-0">
                                    <div title="Stops" class="mt-1 sm-font">STOPS  </div>
                                </div>
                                <div class="column p-0">
                                    <div class="has-text-centered sm-font mt-1">CREATED BY  </div>
                                </div>
                            </b>
                            <transition-group name="list" tag="ul" class="mt-1 truck-list-items custom_scrollbar-1">
                                <li v-for="(truck_job, index) in $parent.jobs(job).filter(e => e.status_id == 1)" 
                                        :key="'trap' + index" class="is-clickable truck-kanboard truck-item truck-list-item columns "
                                        :class="{'has-background-not-opaque': truck_job.collapsed}" @click.prevent="$parent.$parent.collapse_truck_job(truck_job.guid)">
                                        <i class="fas column is-1 p-0 fa-thumbtack is-pulled-left fa-xs is-align-self-center mr-3 ml-1" 
                                            :class="{'has-text-success-dark': truck_job.collapsed, 'has-text-grey-light': !truck_job.collapsed}"></i>
                                        <div class="column mr-1 is-1 p-0 test">
                                            <vue-ellipse-progress                   
                                                :progress="truck_job_percantage(truck_job)"
                                                :size="42"
                                                :thickness="3"
                                                emptyThickness="3%"
                                                lineMode="in-over"
                                                :legend="true"
                                                :legendValue="truck_job_percantage(truck_job)"
                                                :legendFormatter="({currentValue}) => new Intl.NumberFormat('de-DE').format(currentValue)"
                                                legendClass="legend-custom-style"
                                                dash="90 0.9"
                                                animation="reverse 700 400"
                                                >
                                            </vue-ellipse-progress>
                                        </div>
                                        <!--Tansition-->
                                        <truck v-if="truck_job.status_id == 1" class="column is-4 p-0 mx-2 truck-elemets is-align-self-center"
                                            :weights="$parent.truckJobWeightVolume(truck_job)"
                                            :show="false"
                                            :job="truck_job"
                                            :data="$parent.getTruckData(truck_job.details.truck_id, $parent.$parent.warehouses_parameters)">
                                        </truck>
                                        <!-- Materials  -->
                                        <div class="column p-0">
                                            <div class="ml-4"><b>{{get_material_amount(truck_job)}}</b></div>
                                        </div>
                                        <!--stop's amount-->
                                        <div class="column m-0 p-0">
                                            <div style="margin-left:-15px;" v-if="truck_job.stops.length > 0" class="has-text-centered"><b>{{truck_job.stops.length}}</b></div>
                                        </div>
                                        <div class="column p-0 m-0 has-text-grey-light pr-2 has-text-right">
                                            <div class="sm-font">{{truck_job.updated_at ? truck_job.updated_at : truck_job.created_at | local-datetime}}</div>

                                            <div class="sm-font">{{truck_job.creator}}</div>
                                        </div>
                                    </li>
                            </transition-group>
                        </div>
                        <!--################################          DEPARTED    ############################-->
                        <div class=" p-1 is-4  bordered kboard departured-board">
                            <b class="pl-1 columns m-0">
                                <span class="column p-0 is-6">DEPARTED</span>
                                <div class="column p-0">
                                     <div style="margin-left:-15px;" v-if="$parent.$parent.direction" title="Checked out materrials" class="mt-1 sm-font">CHECKED_OUT  </div>
                                    <div v-else title="Checked in materrials" class="mt-1 sm-font">CHECKED_IN  </div>
                                </div>
                                <div class="column p-0">
                                    <div title="Stops" class="mt-1 sm-font">STOPS  </div>
                                </div>
                                <div class="column p-0">
                                    <div class="has-text-centered sm-font mt-1">CREATED BY  </div>
                                </div>
                            </b>
                            <transition-group name="list" tag="ul" class="mt-1 truck-list-items custom_scrollbar-1">
                                <li v-for="(truck_job, index) in $parent.jobs(job).filter(e => e.status_id == 2)" 
                                        :key="'trap' + index" class="is-clickable truck-kanboard truck-item truck-list-item columns "
                                        :class="{'has-background-not-opaque': truck_job.collapsed}" @click.prevent="$parent.$parent.collapse_truck_job(truck_job.guid)">
                                        <i class="fas column is-1 p-0 fa-thumbtack is-pulled-left fa-xs is-align-self-center mr-3 ml-1" 
                                            :class="{'has-text-success-dark': truck_job.collapsed, 'has-text-grey-light': !truck_job.collapsed}"></i>
                                        <div class="column mr-1 is-1 p-0 test">
                                            <vue-ellipse-progress                   
                                                :progress="truck_job_percantage(truck_job)"
                                                :size="42"
                                                :thickness="3"
                                                emptyThickness="3%"
                                                lineMode="in-over"
                                                :legend="true"
                                                :legendValue="truck_job_percantage(truck_job)"
                                                :legendFormatter="({currentValue}) => new Intl.NumberFormat('de-DE').format(currentValue)"
                                                legendClass="legend-custom-style"
                                                dash="90 0.9"
                                                animation="reverse 700 400"
                                                >
                                            </vue-ellipse-progress>
                                        </div>
                                    <!-- <transition name="slide-fade" mode="out-in" :duration="10000" appear :key="'trans' + index"> -->
                                        <truck v-if="truck_job.status_id == 2" class="column is-4 p-0 mx-2 truck-elemets is-align-self-center" 
                                            :weights="$parent.truckJobWeightVolume(truck_job)"
                                            :show="false"
                                            :job="truck_job"
                                            :data="$parent.getTruckData(truck_job.details.truck_id, $parent.$parent.warehouses_parameters)"></truck>
                                    <!-- </transition>  -->
                                        <div class="column p-0">
                                            <div class="has-text-centered"><b>{{get_material_amount(truck_job)}}</b></div>
                                        </div>
                                        <!--stop's amount-->
                                        <div class="column p-0">
                                            <div style="margin-left:-15px;" v-if="truck_job.stops.length > 0" class="has-text-centered"><b>{{truck_job.stops.length}}</b></div>
                                        </div>
                                        <div class="column p-0 m-0 has-text-grey-light pr-2 has-text-right">
                                            <div class="sm-font">{{truck_job.updated_at ? truck_job.updated_at : truck_job.created_at | local-datetime}}</div>

                                            <div class="sm-font">{{truck_job.creator}}</div>
                                        </div>
                                    </li>
                            </transition-group>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>

<script>
import { VueEllipseProgress } from "vue-ellipse-progress";
export default {
    props: ["job"],
    data(){
        return{
        }
    },
    components: { 
        'truck': () => import('@/components/steps/planning/truck.vue'),
        VueEllipseProgress
    },
    methods:{
        truck_job_percantage(truck_job){
			var list = this.$parent.$parent.warehouses_parameters
            if(this.$parent.truckJobWeightVolume(truck_job).Weight 
							&& this.$parent.getTruckData(truck_job.details.truck_id, list).data
							&& this.$parent.getTruckData(truck_job.details.truck_id, list).data.NetWeight){
                let materials_weight = this.$parent.truckJobWeightVolume(truck_job).Weight
                let total_weight = parseFloat(this.$parent.getTruckData(truck_job.details.truck_id, list).data.NetWeight)
                let test = parseFloat(((100 * materials_weight) / total_weight).toFixed(2))
                // console.log('test', typeof test, test)
                return test
            }
            return 0
        },
        get_material_amount(truck_job){
            var amount = 0
            for (let index = 0; index < truck_job.Qty.length; index++) {
                if(truck_job.status_id == 1){
                    amount = amount + truck_job.Qty[index].loaded_amount; 
                }
                else if(truck_job.status_id == 2){
                    amount = amount + truck_job.Qty[index].Qty
                }
            }
            return amount
        }
    },
    computed: {

    }

}
</script>

<style>
.test{
    /* margin-left: -6%; */
    margin-left: -30px;
}
.legend-custom-style{
    font-size: 13px;
    color: #8a4d76;
}
.truck-kanboard .kban-truck-name{
    color:#8694a4;
}
.truck-kanboard:hover .kban-truck-name{
    color:#00838F;
}
.truck-kanboard:hover{
color: #00838F!important;
background: white;
will-change: box-shadow;
transition: box-shadow .1s cubic-bezier(.4, 0, 1, 1), background-color .1s cubic-bezier(.4, 0, .2, 1), color .1s cubic-bezier(.4, 0, .2, 1);
/* box-shadow: 0 5px 6px 0 rgba(0, 0, 0, .14), 0 3px 1px -6px rgba(0, 0, 0, .2), 2px 5px 3px 0 rgba(0, 0, 0, .12); */
}
.truck-kanboard:not(:last-child) {
margin-bottom: 0;
}
.truck-item{
border: solid 1px #e4e4e4;
background: white;
opacity: 0.8;
padding: 5px;
margin-bottom: 10px !important;
/* filter: drop-shadow(4px 4px 4px #ccc); */
/* border-radius: 12px; */
}
.bordered{
border: 1px solid #e4e4e4;
	
}
.bordered > b {
	display: block;
	padding: 2px;
	border: 1px solid #ebebeb;
	background: #ffff;
	color: #666666;
}
.kboard{
	overflow: hidden;
    max-height: 400px;
    min-height: 200px;
}
.truck-list-items{
    overflow-y: scroll;
    height: 100%;
}
.sm-font{
	font-size: 10px;
}
.kahban-wall .truck-side {
    padding: 2px !important;
    font-size: 12px;
    border: 0px !important;
    background: none !important;
}
.kahban-wall{
    width:100%;
    display:flex;
    align-items: stretch;
    gap:5px;
}
.kahban-wall > div{
    flex-grow: 1;
}
.kahban-wall ul{
    padding:12px 12px 33px;
    
}
.has-background-not-opaque{
    opacity: 1;
}
</style>
